import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

import * as firebase from 'firebase/app';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

	contactForm: FormGroup;
	loading = false;
	success = false;

  	constructor(
		private formBuilder: FormBuilder,
		private fun: AngularFireFunctions,
		private snackBar: MatSnackBar) {
	}

	ngOnInit() {
		this.contactForm = this.formBuilder.group({
			name: ['', Validators.required],
      		email: ['', [Validators.required, Validators.email]],
			phone: ['', Validators.required],
			message: ['', Validators.required]
		});
	}

	get name() {
		return this.contactForm.get('name');
	}
	get email() {
		return this.contactForm.get('email');
	}
	get phone() {
		return this.contactForm.get('phone');
	}
	get message() {
		return this.contactForm.get('message');
	}

	onSubmit() {
		this.loading = true;
		const callable = this.fun.httpsCallable('BPAthletescontactEmail');
		callable({
			name: this.name.value,
			email: this.email.value,
			phone: this.phone.value,
			message: this.message.value
		}).subscribe(
		err => {
			console.log(err);
			this.loading = false;
		}, () => {
			this.loading = false;
			this.success = true;
		}
		);
		this.success = true;
	}
}