<a (click)="toTopButton()" id="ToTopButton"><i class="fas fa-chevron-up"></i></a>

<div class="jumbotron_insta">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- <h1>Instagram Feed <a target="_blank" href="https://www.instagram.com/blueprint.athletes/">@blueprint.athletes</a></h1> -->
                <h1>Instagram Feed</h1>
                <iframe src="//lightwidget.com/widgets/bff62cf0627c511e89a4f61882809bea.html" scrolling="no" allowtransparency="true" class="lightwidget-widget" style="width:100%;border:0;overflow:hidden;"></iframe>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_info">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>{{ appTitle }}</h1>
                <h2>BE HUMBLE. HELP OTHERS. STAY THE COURSE.</h2>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button routerLink="/" [routerLinkActive]="'navbar-mobile-active'">Home</button>
                <button routerLink="app-features" [routerLinkActive]="'navbar-mobile-active'">Blueprint Basketball</button>
                <button routerLink="app-features" [routerLinkActive]="'navbar-mobile-active'">Blueprint Junior</button>
                <button routerLink="app-features" [routerLinkActive]="'navbar-mobile-active'">Weight Room Strength & Conditioning</button>
                <button routerLink="app-features" [routerLinkActive]="'navbar-mobile-active'">At Home Strength & Conditioning</button>
                <button routerLink="/contact" [routerLinkActive]="'navbar-mobile-active'">Contact</button>
                <button routerLink="/signup" [routerLinkActive]="'navbar-mobile-active'">Sign Up / Upgrade</button>
                <a target="_blank" href="https://www.facebook.com/groups/blueprintathletes/"><i class="fab fa-facebook"></i></a>
                <a target="_blank" href="https://www.instagram.com/blueprint.athletes/"><i class="fab fa-instagram"></i></a>
                <a target="_blank" href="https://twitter.com/BPAthletesApp"><i class="fab fa-twitter"></i></a>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron_footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <p>© {{ appTitle }}, {{ year }}. All Rights Reserved.</p>
                <p><a routerLink="/terms"> Terms</a> | <a routerLink="/privacy"> Privacy</a> | <a routerLink="/agreement"> Agreement</a></p>
                <p>Design by: © <a href="https://www.thegreenedgellc.com" target="_blank"  style="color: #1ABC9C;">The Green Edge LLC</a></p> 
            </div>
        </div>
    </div>
</div>