<div class="jumbotron_404">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <h1>404</h1>
                <h2>Page Not Found</h2>
                <p>We can't find the page you're looking for. You can either return to the home page or contact our support team.</p>
                <div class="center">
                    <button class="primary" routerLink="/">Visit Homepage</button>
                    <button class="secondary" routerLink="/contact">Contact Us</button>
                </div>
            </div>
        </div>
    </div>
</div>