<div class="jumbotron-white">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1>Software License Agreement</h1>
        <hr>
        <p>
            NOTICE: PLEASE READ THIS CONTRACT CAREFULLY. THIS IS A LICENSE AGREEMENT (“AGREEMENT”) BETWEEN YOU AS THE LICENSEE AND BLUEPRINT ATHLETES (“BLUEPRINT”) AS THE LICENSOR. THE SOFTWARE IS LICENSED FOR USE ONLY PURSUANT TO THE TERMS SET FORTH HEREIN. YOU AGREE THAT THIS AGREEMENT IS ENFORCEABLE LIKE ANY WRITTEN NEGOTIATED AGREEMENT SIGNED BY YOU. THE TERM “YOU” OR “LICENSEE” SHALL MEAN THE LEGAL ENTITY FOR WHICH THE SOFTWARE HAS BEEN OBTAINED AND ON WHOSE BEHALF IT IS USED, AS INDICATED IN YOUR ORDER (E.G., THE SCHOOL OR ATHLETIC ORGANIZATION). IF YOU DO NOT AGREE, DO NOT USE THE SOFTWARE.
            <br><br>
            THE PERSON PLACING THIS ORDER REPRESENTS THAT THEY HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT ON BEHALF OF THE LICENSEE.
            <br><br>
            THIS AGREEMENT IS EFFECTIVE AS OF THE DATE OF YOUR INITIAL ORDER (“EFFECTIVE DATE”).
            <br><br>
            1. Definitions.
            <br><br>
            1.1 “Client Software” means those elements of the Software intended for installation on laptops or other personal computers.
            <br><br>
            1.2 “Coaching Data” means Licensee coaching tools and data, such as voice, drawing and textual annotations of Video Content, playbooks, diagrams, coaching presentation and testing materials, and communications among End Users, as contained in the Software. Coaching Data does not include Coach Contact Information.
            <br><br>
            1.3 ”Coach Contact Information” means name, email address, phone number, and any other contact information of any coaches provided by Licensee to Blueprint Athletes.
            <br><br>
            1.4 “Documentation” means the standard user, operations and training manuals that Blueprint Athletes makes available to its licensees generally in connection with the Software.
            <br><br>
            1.5 “End Users” means members of each Licensee Team and such Team’s respective coaching and support staff.
            <br><br>
            1.6 “Enhancements” means improvements, updates, fixes, or version upgrades which offer enhancements to the functionality, performance or reliability of the Software and that are made available by Blueprint to its licensees generally; provided, however, that Enhancements shall not include any new platforms, modules or other software that Blueprint may develop from time to time, which are generally licensed separately by Blueprint.
            <br><br>
            1.7 “Intellectual Property Rights” means any rights under any patent (including patent applications and disclosures), copyright, trademark, trade secret, or other intellectual property right recognized in any country or jurisdiction in the world.
            <br><br>
            1.8 “Software” means Blueprint's non-professional sports and sports team communication, training and management system software marketed as “Blueprint”, in object code form only, and the related Documentation, and any Enhancements provided by Blueprint Athletes to Licensee in Blueprint's sole discretion. Software shall include the Client Software.
            <br><br>
            1.9 “Statistical Data” means Team rosters and performance statistics for each Team and End User, as inputted by Licensee into the Software.
            <br><br>
            1.10 “Team” means each athletic team identified in an order for which Licensee has paid in full any amounts owed under this Agreement.
            <br><br>
            1.11 “Video Content” means any and all Team, End User video clips and other game or practice film, uploaded by Licensee to the Software, including, the voices, performances, poses, acts, plays, appearances, pictures, images, likeness, photographs, silhouettes and other reproductions of the physical likeness and sound of the players, coaches, and all others appearing in the Video Content.
            <br><br>
            1.12 “Vital Information” means physical characteristics (e.g., height and weight) and contact information concerning any End User, as input by Licensee into the Software or as otherwise publicly available.
            <br><br>
            2. Licenses.
            <br><br>
            2.1 Software License Grant. Subject to this Agreement, Blueprint grants to Licensee the non-exclusive, non-transferable, revocable right during the Term (as defined below) to (i) download and install the Client Software on End User laptops and other personal computers, and (ii) access and use the Software for internal use by End Users. Licensee may grant its End Users the rights in (i) and (ii). As a condition of the grant in this section, Licensee may not modify, transfer, or otherwise sublicense or distribute the Software to any third party, and Licensee may not disassemble, decompile or reverse engineer the Software. Blueprint shall have the right to immediately terminate the foregoing license upon any breach (including any attempted and/or threatened breach) of this Section 2.1.
            <br><br>
            2.2 Grants to Blueprint. Subject to this Agreement, Licensee grants the following license rights to Blueprint (and its licensees, sublicensees, distributors and subdistributors):
            <br><br>
            (i) Video Content: the non-exclusive, royalty-free right, to all Intellectual Property Rights of Licensee in the Video Content, to use Licensee’s Video Content for the purpose of enabling End Users to use the Software during the Term and, if Licensee authorizes, through the Software, the release, (which includes the right to sublicense, license, distribute or subdistribute) of the Video Content, in whole or in part, to third parties, including but not limited to Blueprint's distributors, independent contractors and agents, to (a) use such released Video Content to provide the Software community features to End Users and to other third parties during the Term, and (b) to reproduce, transmit, display, exhibit, distribute, index, comment on, modify, create derivative works based upon (including inserting advertising therein), perform and otherwise use such released Video Content, in whole or in part, in perpetuity in all media formats and channels now known or hereafter devised (including on Blueprint's website, third party web sites, cable networks and stations, broadband and wireless platforms, products and services) for any and all purposes, including entertainment, news, advertising, promotional, marketing, publicity, trade or commercial purposes, all without further notice to, or permission from, Licensee, with or without attribution and without any royalty or payment obligations, which rights in this subsection (b) shall survive any termination or expiration of this Agreement (subsection (b) being the “Distribution License”).
            (ii) Coaching Data: the non-exclusive, royalty-free right, to all Intellectual Property Rights of Licensee in the Coaching Data, to use Licensee’s Coaching Data for the purpose of enabling End Users and other third parties to use the Software during the Term.
            (iii) Vital Information: the non-exclusive, royalty-free and perpetual right to all Intellectual Property Rights of Licensee in the Vital Information to use the Vital Information in connection with the Software, on Blueprint's website and otherwise in connection with the Video Content.
            (iv) Statistical Data: the non-exclusive, royalty-free and perpetual right, to all Intellectual Property Rights of Licensee to use the Statistical Data in any manner in connection with the Video Content.
            (v) Hosting: Without limiting the above, Blueprint Athletes may sublicense the rights in this Section 2 during the Term as necessary to enable any third party hosting of the Software.
            2.3 Ownership. Except for the licenses provided in this Section 2, as between Blueprint and Licensee (i) Blueprint owns all Intellectual Property Rights in the Software, Blueprint's marks and business model, Feedback (as defined below), and any other rights not expressly granted to Licensee herein and (ii) Licensee owns all Intellectual Property Rights in the Video Content, Coaching Data, and Statistical Data. No other licenses are granted.
            <br><br>
            3. Feedback.
            <br><br>
            Blueprint Athletes may solicit from Licensee and/or End Users, and/or Licensee and/or End Users may submit to Blueprint comments, information, questions, data, ideas, enhancement requests, recommendations, description of processes, or other information concerning the Software (“Feedback”). Blueprint owns all Intellectual Property Rights in any Feedback and may use such Feedback for any purpose related to the Software without further approval or acknowledgement, and Licensee and each End User hereby assigns to Blueprint any and all rights of Licensee in any such Feedback throughout the universe in perpetuity.
            <br><br>
            4. Promotion.
            <br><br>
            Blueprint Athletes may, in its sole discretion, issue a press release to announce Licensee as a new customer. Subject to Blueprint's confidentiality obligations hereunder, Blueprint may display on Blueprint's website and in any and all promotional materials (irrespective of the means of exploitation) a case study or other customer usage scenario referencing or featuring Licensee. Blueprint may prepare and utilize testimonials of a reasonable number of End Users, subject to Licensee’s prior consent (to the extent Licensee is entitled to grant such consent). Licensee hereby grants to Blueprint, and represents to Blueprint that it may grant, a non-exclusive, non-transferable, royalty-free license for Blueprint to make use of Licensee’s name or logo during the Term on Blueprint's website and in any and all promotional materials (irrespective of the means of exploitation). All such use shall inure to the benefit of Licensee, and Blueprint shall have no implied right to any other intellectual property of Licensee except as set forth in this Agreement. Blueprint shall use its best efforts to comply with any use guidelines that Licensee provides to Blueprint in writing; provided, that an inadvertent failure to comply shall not be a breach of this Agreement.
            <br><br>
            5. Support.
            <br><br>
            Blueprint will provide Licensee with the maintenance and support services for the Software indicated on Licensee’s order, subject to Licensee’s payment of the fees as set forth in Section 7 below.
            <br><br>
            7. Payment.
            <br><br>
            7.1 Costs. Licensee will pay Blueprint the fees, in such amounts and at such times, as indicated on Licensee’s order(s). Fees are payable in advance of the then-applicable periodic term and are not refundable in the event of any cancellation. Blueprint reserves the right to terminate or limit Licensee’s use of the Software at any time without notice to Licensee in the event payment has not been received within thirty (30) days of the date due. Licensee is responsible for all costs and expenses related to the use of the Software (e.g., cameras, laptops, and any other equipment or software used by End Users).
            <br><br>
            7.2 Credit Card Payments. By signing up for Blueprint Athletes with a credit card, you are expressly agreeing that we are authorized to charge you the annual membership fee at the then current rate plus any applicable tax, and any other charges you may incur in connection with your use of Blueprint. Please note that prices and charges are subject to change with notice. Your Blueprint account, which may start with a free trial portion, will continue in effect unless and until you cancel your account or we terminate it. You must cancel your account before it renews each year in order to avoid billing of the next period’s account fees to your credit card. We will bill the account fee at the then current rate plus any applicable tax to the credit card you provide to us during registration (or to a different credit card if you change your account information). Account fees are fully earned upon payment. You may cancel your Blueprint account at any time, and cancellation will be effective immediately. We do not provide refunds or credits for any partial-period accounts.
            <br><br>
            8. Confidentiality.
            <br><br>
            Each party (a “Receiving Party”) may be provided or have access to Confidential Information (as defined below) of the other party (a “Disclosing Party”). Confidential Information will not include anything that is authorized by Licensee for disclosure to third parties pursuant to the terms of this Agreement or use of the Software, as provided herein, already in the possession of the Receiving Party without obligation of confidence, is independently developed by the Receiving Party without use of Confidential Information, is or becomes available to the general public without breach of this Agreement, or is rightfully received by the Receiving Party from a third party without obligation of confidence. A Receiving Party agrees that it will not, without the consent of the other party, disclose Confidential Information to third parties or use it in any way except as set forth herein, and take actions reasonably necessary to protect the confidentiality of the Confidential Information. “Confidential Information” means (i) any proprietary information, technical data, trade secrets or know-how of Blueprint, including, but not limited to, research, product plans, and proprietary information concerning products and services, pricing, or other business or technical information of Blueprint that is designated to be confidential or proprietary or which reasonably appears to be confidential or proprietary; and (ii) to the extent not otherwise released by Licensee and except as provided in the Privacy Policy, the Video Content, Coaching Data and Player Confidential Profile Data.
            <br><br>
            9. Term and Termination.
            <br><br>
            The term of this Agreement shall commence upon the Effective Date and will continue for an initial period, as specified in Licensee’s initial order, and thereafter shall be automatically renewed on an annual basis, as specified in an order, for so long as Licensee’s order(s) are in effect (the “Term”), unless otherwise terminated, as provided below. Licensee may provide notice of cancellation of the use of the Software by one or more Teams, or this Agreement, at any time prior to the expiration of the then-current annual term. Blueprint may terminate this Agreement if Licensee materially breaches any term of this Agreement and fails to cure such breach within thirty (30) days after written notice thereof. Upon termination, Licensee shall have no further rights hereunder and shall promptly remove the Software and Enhancements from each and every Licensee and End User computer.
            <br><br>
            10. Representations and Warranties.
            <br><br>
            10.1 Licensee represents to Blueprint Athletes as follows: (i) it has the authority to enter into this Agreement and perform its obligations under this Agreement; (ii) it has all secured and will maintain any and all rights, consents and/or releases, including all Intellectual Property Rights, necessary to grant the licenses herein, including from any End Users, independent contractors, governing athletic bodies, conferences or organizations, and parents of End Users that are minors; (iii) the Video Content and the Coaching Data, as incorporated into the Software by Licensee and Blueprint (or Blueprint's) exploitation thereof in accordance with the terms of this Agreement, do not violate, infringe upon, or misappropriate the Intellectual Property Rights, or any other right, of any third party; (iv) there are no existing or threatened claims or litigation which would materially adversely affect or materially adversely impair Licensee’s ability to perform under this Agreement; (v) it has no agreement with or obligations to any third party with respect to the rights herein granted which conflict or interfere with or adversely affect any of the provisions of this Agreement or the use or enjoyment by Blueprint of any of the rights herein granted; and (vi) Licensee has not sold, assigned, transferred or conveyed, and will not sell, assign, transfer, or convey, to any party any right, title, or interest in and to the rights herein granted or any part thereof, adverse to or in derogation of the rights herein granted to Blueprint.
            <br><br>
            10.2 Blueprint represents to Licensee that it has the authority to enter into this Agreement and perform its obligations under this Agreement.
            <br><br>
            10.3. EXCEPT FOR THE LIMITED WARRANTIES SET FORTH ABOVE, LICENSEE’S USE OF THE SOFTWARE IS AT LICENSEE’S OWN RISK AND PROVIDED AS-IS, WITHOUT ANY WARRANTIES, AND EACH PARTY DISCLAIMS ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND ANY ARISING OUT OF COURSE OF DEALING, USAGE OR TRADE.
            <br><br>
            11. Blueprint Indemnification.
            <br><br>
            11.1 Blueprint will defend or settle any action brought against Licensee to the extent that it is based upon a third party claim that the Software, as provided by Blueprint to Licensee under this Agreement and used within the scope of this Agreement, infringes any U.S. patent or any copyright or misappropriates any trade secret with regard to any third party (a “Claim”), and will pay any costs, damages and reasonable attorneys’ fees attributable to such Claim that are awarded against Licensee, provided that Licensee: (i) promptly notifies Blueprint in writing of the Claim; (ii) grants Blueprint sole control of the defense and settlement of the claim; and (iii) provides Blueprint, at Blueprint's expense, with all assistance, information and authority reasonably required for the defense and settlement of the Claim. Notwithstanding the foregoing, any and all of Blueprint's obligations under this Section 11 shall be limited to an amount equal to the subscription fees paid to Blueprint under this Agreement.
            <br><br>
            11.2 If Licensee’s use of any of the Software hereunder is, or in Blueprint's opinion is likely to be, enjoined due to a Claim, Blueprint may, at its sole option and expense, (i) use commercially reasonable efforts to procure for Licensee the right to continue using such Software under the terms of this Agreement; or (ii) use commercially reasonable efforts to replace or modify such Software so that it is non-infringing and substantially equivalent in function to the enjoined Software; or (iii) terminate Licensee’s rights and Blueprint's obligations hereunder with respect to such Software and refund to Licensee all fees paid for such Software for the then-current annual term.
            <br><br>
            11.3 Notwithstanding anything herein to the contrary, Blueprint will have no liability for any infringement or misappropriation claim of any kind to the extent that related to: (i) modifications to the Software made by a party other than Blueprint; (ii) the combination, operation or use of the Software or any other material licensed to Licensee hereunder with equipment, devices, software or data not supplied by Blueprint; or (iii) Licensee’s failure to use an Enhancement provided by Blueprint; (iv) Licensee’s breach of this Agreement; or (v) due to any action or inaction of Licensee.
            <br><br>
            11.4 THE INDEMNIFICATION PROVISIONS OF SECTION 11.1 AND THE REFUND PROVISIONS OF SECTION 11.2, SUBJECT TO THE EXCLUSIONS OF SECTION 11.3, SET FORTH BLUEPRINT'S SOLE AND EXCLUSIVE OBLIGATIONS, AND LICENSEE’S SOLE AND EXCLUSIVE REMEDIES, WITH RESPECT TO INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS OF ANY KIND.
            <br><br>
            12. Licensee Indemnification.
            <br><br>
            Licensee agrees to indemnify and hold Blueprint Athletes, its affiliates, and their respective directors, officers, and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of Licensee’s breach of this Agreement, including any representations and warranties set forth above. This Section 12 shall survive any termination or expiration of this Agreement.
            <br><br>
            13. Limitation of Liability.
            <br><br>
            13.1 EXCEPT FOR BLUEPRINT'S INDEMNIFICATION OBLIGATIONS, IN NO EVENT WILL BLUEPRINT BE LIABLE TO LICENSEE UNDER ANY THEORY OF TORT, CONTRACT, STRICT LIABILITY OR OTHER LEGAL OR EQUITABLE THEORY FOR ANY LOST PROFITS, LOST VIDEO CONTENT, COACHING DATA, OR STATISTICAL DATA, EXEMPLARY, PUNITIVE, SPECIAL, INCIDENTAL, INDIRECT OR OTHER CONSEQUENTIAL DAMAGES, EACH OF WHICH IS HEREBY EXCLUDED BY AGREEMENT, REGARDLESS OF WHETHER OR NOT EITHER PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <br><br>
            13.2 BLUEPRINT'S TOTAL CUMULATIVE LIABILITY TO LICENSEE FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES OF LIABILITY WILL NOT EXCEED THE FEES PAID TO BLUEPRINT BY LICENSEE PURSUANT TO THIS AGREEMENT.
            <br><br>
            14. Cooperation.
            <br><br>
            In the event Licensee becomes aware of any infringement by a third party of the Video Content and/or the Statistical Data, Licensee shall promptly notify Blueprint. In the event Blueprint becomes aware of, or is notified by Licensee of, any infringement by a third party of the Video Content and/or the Statistical Data, Licensee shall cooperate with Blueprint, at Blueprint's reasonable request, in preparing any communications and/or bringing and prosecuting any action or suit with regard to such infringement.
            <br><br>
            15. Miscellaneous.
            <br><br>
            If for any reason a court of competent jurisdiction finds any provision of this Agreement invalid or unenforceable, that provision of the Agreement will be enforced to the maximum extent permissible and the other provisions of this Agreement will remain in full force and effect. The parties’ relationship is that of independent contractors. Blueprint may assign this Agreement or any of its rights or obligations hereunder (in whole or in part) without Licensee’s consent. Licensee may not assign this Agreement without the prior written consent of Blueprint. This Agreement is governed by the laws of the State of California, however, that Blueprint shall have the right to commence and prosecute any legal or equitable action or proceeding before any other U.S. court of competent jurisdiction to obtain injunctive or other relief. Any notices must be given in writing to the other party at the contact information indicated on an order(s) or Blueprint's website, and shall be deemed given immediately upon personal delivery, three (3) days after deposit in the United States mail by certified or registered mail, one (1) day after overnight express courier; or upon confirmed transmission by fax or confirmed email receipt.
        </p>
      </div>
    </div>
  </div>
</div>
