export const environment = {
  production: false,
  firebase: {
    host: 'localhost:5001',
    apiKey: 'AIzaSyAdIuLGTstwv-dO1E_6qqqV9ltfCBCtWYc',
    authDomain: 'horford-hoops-plus.firebaseapp.com',
    databaseURL: 'https://horford-hoops-plus.firebaseio.com',
    projectId: 'horford-hoops-plus',
    storageBucket: 'horford-hoops-plus.appspot.com',
    messagingSenderId: '942163230714'
  }
};

export const stripe = Stripe('pk_live_2BHIbCGmi1sQ6p6ritVubrA7');
export const elements = stripe.elements();
