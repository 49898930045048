import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogPlayerComponent } from '../signup/dialog-player/dialog-player.component';
import { DialogTeamComponent } from '../signup/dialog-team/dialog-team.component';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  constructor (
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
  }

  signupPlayer() {
    this.openDialogPlayer();
  }

  signupTeam() {
    this.openDialogTeam();
  }

  openDialogPlayer() {
    const dialogRef = this.dialog.open(DialogPlayerComponent, {
      // data: this.profile,
      panelClass: 'custom-dialog-container',
    });
    // console.log(this.episodes[episodeId]);
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

  openDialogTeam() {
    const dialogRef = this.dialog.open(DialogTeamComponent, {
      // data: this.profile,
      panelClass: 'custom-dialog-container',
    });
    // console.log(this.episodes[episodeId]);
    dialogRef.afterClosed().subscribe(result => {
      // console.log(`Dialog result: ${result}`);
    });
  }

}
