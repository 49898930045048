<div class="navbar-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a target="_blank" href="https://twitter.com/BPAthletesApp"><i class="fab fa-twitter"></i></a>
                <a target="_blank" href="https://www.instagram.com/blueprint.athletes/"><i class="fab fa-instagram"></i></a>
                <a target="_blank" href="https://www.facebook.com/groups/blueprintathletes/"><i class="fab fa-facebook"></i></a>
                <!-- <button (click)="openDialog()">View Promotion</button> -->
            </div>
        </div>
    </div>
</div>

<div class="navbar-desktop">
    <div class="container-fluid">
          <mat-toolbar>
              <mat-toolbar-row>
                  <a routerLink="/"><img src="../assets/img/logos/logo.png"></a>
                  <a routerLink="/"><h1>Blueprint</h1><h2>Athletes</h2></a>
                  <span class="spacer"></span>
                  <button class="primary" routerLink="/" [routerLinkActive]="'navbar-mobile-active'" [routerLinkActiveOptions]="{ exact: true }">Home</button>
                  <button class="primary" routerLink="/app-features" [routerLinkActive]="'navbar-mobile-active'">App Features</button>
                  <button class="primary" routerLink="/contact" [routerLinkActive]="'navbar-mobile-active'">Contact</button>
                  <button class="secondary" routerLink="/signup" [routerLinkActive]="'navbar-mobile-button-active'">Sign Up / Upgrade<i class="fas fa-sign-in-alt"></i></button>
            </mat-toolbar-row>
        </mat-toolbar>
    </div>
</div>
  
<div class="navbar-mobile">
  <div class="container-fluid">
    <div class="row">
      <div class="navbar-mobile-left">
        <img src="../assets/img/logos/logo.png">
        <a routerLink="/"><h1>Blueprint</h1><h2>Athletes</h2></a>
      </div>
      <div class="navbar-mobile-center">
        <!-- <p>{{ appTitle }}</p> -->
      </div>
      <div class="navbar-mobile-right">
        <button (click)="toggleSideNav()"><i class="material-icons">menu</i></button>
      </div>
    </div>
  </div>
</div>

<div id="sideNav">
  <div class="container-fluid">
    <div class="row">
      <div class="side-nav-left">
        <img src="../assets/img/logos/logo.png">
        <a routerLink="/"><h1>Blueprint</h1><h2>Athletes</h2></a>
      </div>
      <div class="side-nav-center">
        <!-- <p>{{ appTitle }}</p> -->
      </div>
      <div class="side-nav-right">
        <button (click)="toggleSideNav()"><i class="material-icons">close</i></button>
      </div>
    </div>
    <div class="row">
      <div class="side-nav-content">
        <mat-divider></mat-divider>
        <button class="primary" mat-button (click)="toggleSideNav()" routerLink="/" [routerLinkActive]="'navbar-mobile-active'" [routerLinkActiveOptions]="{ exact: true }">Home</button>
        <mat-divider></mat-divider>
        <button class="primary" mat-button (click)="toggleSideNav()" routerLink="app-features" [routerLinkActive]="'navbar-mobile-active'">App Features</button>
        <mat-divider></mat-divider>
        <button class="primary" mat-button (click)="toggleSideNav()" routerLink="/contact" [routerLinkActive]="'navbar-mobile-active'">Contact</button>
        <mat-divider></mat-divider>
        <button class="secondary" mat-button (click)="toggleSideNav()" routerLink="/signup" [routerLinkActive]="'navbar-mobile-button-active'">Sign Up / Upgrade</button>
        <!-- <mat-divider></mat-divider>
        <button (click)="openDialog()" (click)="toggleSideNav()" >View Promotion</button>
        <mat-divider></mat-divider> -->
        <div class="social-buttons">
          <a target="_blank" href="https://www.facebook.com/groups/blueprintathletes/"><i class="fab fa-facebook"></i></a>
          <a target="_blank" href="https://www.instagram.com/blueprint.athletes/"><i class="fab fa-instagram"></i></a>
          <a target="_blank" href="https://twitter.com/BPAthletesApp"><i class="fab fa-twitter"></i></a>
        </div>
      </div>
    </div>
  </div>
</div>