<div class="jumbotron-login">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <button class="close" (click)="close();"><i class="fas fa-times"></i></button>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Coach Signup / Upgrade</h1>
                <!-- <h2><b style="color: #49ABF0;">30% Off</b> when you purchase a Yearly Subcription Online!</h2> -->
                <p><b>Create a New Account</b> by completing below and selecting "Create an Account".</p>
                <p>--- Or ---</p>
                <p><b>Upgrade (An Existing Account)</b> by entering your account information and selecting "Upgrade to Premium"</p>
                <form #checkout="ngForm" class="login">
                    <mat-error>{{ error }}</mat-error>
                    <mat-form-field>
                        <input matInput placeholder="Enter your email" name="email" [(ngModel)]="email" required>
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput placeholder="Enter your password" [type]="passwordHidden ? 'password' : 'text'"
                            name="password" [(ngModel)]="password">
                        <button mat-icon-button matSuffix (click)="passwordHidden = !passwordHidden"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHidden">
                        <mat-icon>{{passwordHidden ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-card-actions>
                        <button class="primary" (click)="login()">Upgrade to Premium</button>
                        <button class="secondary" (click)="register()">Create an Account</button>
                    </mat-card-actions>
                </form>
            </div>
        </div>
        
    </div>
</div>