import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Custom Material Module
import { CustomMaterialModule } from './material';

// HTTP Client Module
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from './http.service';

// App Routers
import { NavComponent } from './nav/nav.component';
import { DialogComponent } from './dialog/dialog.component';
import { HomeComponent } from './home/home.component';
import { AppFeaturesComponent } from './app-features/app-features.component';
import { ContactComponent } from './contact/contact.component';
import { SignupComponent } from './signup/signup.component';
import { DialogPlayerComponent } from './signup/dialog-player/dialog-player.component';
import { DialogTeamComponent } from './signup/dialog-team/dialog-team.component';
import { ProfileComponent } from './profile/profile.component';
import { FourZeroFourComponent } from './four-zero-four/four-zero-four.component';
import { FooterComponent } from './footer/footer.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AgreementComponent } from './agreement/agreement.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireFunctionsModule, FunctionsRegionToken } from '@angular/fire/functions';
import { environment } from '../environments/environment';
import { PromocodeComponent } from './promocode/promocode.component';
import { CoachProfileComponent } from './coach-profile/coach-profile.component';


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    DialogComponent,
    HomeComponent,
    AppFeaturesComponent,
    ContactComponent,
    SignupComponent,
    DialogPlayerComponent,
    DialogTeamComponent,
    ProfileComponent,
    FourZeroFourComponent,
    FooterComponent,
    TermsComponent,
    PrivacyComponent,
    AgreementComponent,
    PromocodeComponent,
    CoachProfileComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CustomMaterialModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireFunctionsModule,
  ],
  entryComponents: [DialogComponent],
  providers: [
    HttpService,
    { provide: FunctionsRegionToken, useValue: 'us-central1' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
