import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { elements, stripe } from '../../environments/environment';
import { NgForm } from '@angular/forms';
import { reject } from 'q';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FirebaseListObservable } from '@angular/fire/database-deprecated';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, AfterViewInit, OnDestroy {
  breakpoint: number;
  private lastCheckoutClickTime;

  constructor(private router: Router, private afAuth: AngularFireAuth,
              private afDatabase: AngularFireDatabase,
              private cd: ChangeDetectorRef,
              private fns: AngularFireFunctions) {

    this.afAuth.authState.subscribe((user) => {
      if (user) {
        this.email = user.email;
        this.userId = user.uid;
        this.afDatabase.object(`user/${this.userId}/isAtleastOneSubscriptionActive`).valueChanges().subscribe(val => {
          console.log('has active subscriptions' + val);
          this.subscriptionActive = val as boolean || false;
          this.lastCheckoutClickTime = 0;
          this.updatePaymentValue();
        }, error => {
          console.log(error);
          this.error = error;
          this.lastCheckoutClickTime = 0;
          this.updatePaymentValue();
        });
      } else {
        this.lastCheckoutClickTime = 0;
        this.updatePaymentValue();
        this.logout();
      }
    }, error => console.log(error),
      () => console.log('subscribe complete'));
  }
  private userId: string;
  private subscriptionPlan = 'blueprintathletes-yearly-6999';

  email = '';
  subscriptionActive = true;

  // @ViewChild('cardInfo') cardInfo: ElementRef;
  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;


  // Element used to mount the button
  // @ViewChild('payElement') payElement: ElementRef;
  @ViewChild('payElement', { static: true }) payElement: ElementRef;
  yearlyPriceString = '$69.99';
  monthlyPriceString = '$6.99';
  checkoutButtonString = 'Pay $69.99';
  couponEntered = '';
  subscriptionSelection = 'yearly';
  private timeout = null;
  couponError: any;
  couponStatus: any;

  private BASE_YEARLY_PRICE = 69.99;

  private BASE_MONTHLY_PRICE = 6.99;


  ngOnInit() {
  }

  logout() {
    this.afAuth.auth.signOut().then(() => this.routeToLogin());
  }

  private routeToLogin() {
    console.log('logging user out');
    this.router.navigate(['/signup']);
  }


  ngAfterViewInit() {
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);

  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({error}) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    if (Date.now() - this.lastCheckoutClickTime < 4000) { // wait 3 seconds before allowing another click
      console.log('checkout already in progress, ignore click');
      return;
    }
    this.checkoutButtonString = 'Checkout in progress... please wait';
    this.lastCheckoutClickTime = Date.now();
    const {token, error} = await stripe.createToken(this.card);

    if (error) {
      console.log('Something is wrong:', error);
      this.lastCheckoutClickTime = 0;
      this.updatePaymentValue();
    } else {
      console.log('Success!', token);
      // ...send the token to the your backend to process the charge
      const callable = this.fns.httpsCallable('checkoutForWeb');
      callable({
        coupon: this.couponEntered,
        subscriptionPlan: this.subscriptionPlan,
        stripeToken: token.id
      }).subscribe(
        response => {
          console.log(response);
          if (response.status === 'failed') {
            this.lastCheckoutClickTime = 0;
            this.error = response.message;
            this.updatePaymentValue();
          }
        }, errorResponse => {
        console.log(errorResponse);
        this.error = error.toString();
        this.lastCheckoutClickTime = 0;
        this.updatePaymentValue();
      });
    }
  }

  updatePaymentValue() {
    this.subscriptionPlan = this.subscriptionSelection === 'yearly' ? 'blueprintathletes-yearly-6999' : 'blueprintathletes-monthly-699';
    this.checkoutButtonString = `Pay $${this.subscriptionSelection === 'yearly' ? this.BASE_YEARLY_PRICE : this.BASE_MONTHLY_PRICE}`;
  }

  validateCoupon() {
    if (this.couponEntered.length < 4) {
      console.log('Coupon too short. Not validating');
      this.couponError = '';
      this.couponStatus = '';
      const priceBeforeDiscount = this.subscriptionSelection === 'yearly' ? this.BASE_YEARLY_PRICE : this.BASE_MONTHLY_PRICE;
      this.checkoutButtonString = `Pay ${priceBeforeDiscount}`;
      return;
    }
    if (this.timeout) { // if there is already a timeout in process cancel it
      window.clearTimeout(this.timeout);
    }

    this.timeout = window.setTimeout(() => {
      this.timeout = null;
      const callable = this.fns.httpsCallable('getCoupon');
      callable({coupon: this.couponEntered}).subscribe(response => {
        console.log(response);
        if (response.status === 'invalid') {
          this.couponError = 'Invalid Coupon';
          this.couponStatus = '';
          const priceBeforeDiscount = this.subscriptionSelection === 'yearly' ? this.BASE_YEARLY_PRICE : this.BASE_MONTHLY_PRICE;
          this.checkoutButtonString = `Pay ${priceBeforeDiscount}`;
        } else {
          this.couponError = '';
          const discountPercentage = response.discountPercentage;
          const discountDuration = response.discountDuration;
          // this.couponStatus = `${discountPercentage}% will be applied. Discount duration: ${discountDuration}`;
          this.couponStatus = `Discount duration: ${discountDuration}`;
          const priceBeforeDiscount = this.subscriptionSelection === 'yearly' ? this.BASE_YEARLY_PRICE : this.BASE_MONTHLY_PRICE;
          const priceAfterDiscount = priceBeforeDiscount - (priceBeforeDiscount * discountPercentage / 100);
          this.checkoutButtonString = `Pay $${Math.round(priceAfterDiscount * 100) / 100}`;
        }
      }, error => console.log(error), () => console.log('done validating coupon???'));
    }, 500);
  }
}
