<div class="jumbotron-dialog">
    <div class="background">
        <!-- <img src="/assets/img/logos/logo.png"> -->
        <h1>Blueprint</h1>
        <h2>Athletes</h2>
        <h3>Basketball Training</h3>
        <img class="appstore_img" src="/assets/img/logos/app_store_logo.png">
        <img class="appstore_img" src="/assets/img/logos/google_play_store.png">
        <h4>30% off</h4>
        <h5>If you purchase a Yearly Subscription Online!</h5>
        <button matDialogClose routerLink="/signup" >Register Now</button>
    </div>
</div>