<div class="jumbotron-white">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1>Terms & Conditions</h1>
        <hr>
        <p>
          Welcome to Blueprint Athletes, and thanks for accessing our services. The services available on and through this website (the “Services” and the “Site”) are provided by Blueprint (“Blueprint”).
          <br><br>
          Your use of the Services is governed by these terms and conditions (this “Agreement”) and, if applicable, the Blueprint Software License Agreement (“BSLA”). By using the Services, you agree to be bound by this Agreement, whether you are a visitor, which means that you simply browse the Site or any of its applications, or you are a user with a registered account (“Registered User”). Blueprint Athletes may at any time in its sole discretion (i) modify this Agreement and such modification shall be effective once posted to the Site, (ii) change the Site, including eliminating or discontinuing any Services or other feature of the Site; and/or (iii) deny or terminate your use of and/or access to the Site.
          <br><br>
          You agree to be bound to any changes to this Agreement when you use the Services or access the Site immediately after any modification to this Agreement has been posted. It is therefore important that you review this Agreement regularly to ensure you are updated as to any changes.
          <br><br>
          The term “User” refers to any visitor to the Site or Registered User, unless provided otherwise. You are only authorized to use the Services if you agree to abide by all applicable laws, this Agreement and the BSLA, if applicable. Please read this Agreement carefully and save it. If you do not agree with it, you should leave the Site or any of its applications and discontinue use of the Services immediately. If you wish to become a Registered User, communicate with other Users, and make full use of the Services, you must read this Agreement and indicate your acceptance during the registration process.
          <br><br>
          PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, AND A DISPUTE RESOLUTION CLAUSE THAT GOVERNS HOW DISPUTES WILL BE RESOLVED.
          <br><br>
          1. Eligibility.
          <br><br>
          Use of Services is void where prohibited. By using the Services, you represent to us that (a) any registration information that you submit is truthful, accurate and otherwise owned by (or licensed to) you; and (b) your use of the Services does not violate any applicable law or regulation.
          <br><br>
          2. Registration.
          <br><br>
          Browsing of the Site’s public pages is provided free of charge to any person. However, access to certain functionalities of the Site will require you to register with and/or provide certain information to us. We reserve the right to decline to provide Services to any person for any or no reason. When you sign up to become a Registered User, you will be asked to create a password. You are entirely responsible for maintaining the confidentiality of your password. You agree not to use the account, username, or password of another User at any time or disclose your password to any third party. You agree to notify us immediately if you suspect any unauthorized use of your account or access to your password. You are solely responsible for any and all uses of your account.
          <br><br>
          In order to participate in certain Services, you may be notified that you are required to download software or content and/or agree to additional terms and conditions. Any such additional terms are hereby incorporated by reference into this Agreement.
          <br><br>
          3. Limited License.
          <br><br>
          Blueprint Athletes authorizes you to copy materials on this Site to your hard drive solely for the purpose of viewing and using the Services on your computer. You may also print portions of the Site in hard copy for the sole purpose of facilitating your personal, noncommercial use and retention of information from the Site.; provided, that (i) you must retain all trademark, copyright, and other proprietary notices contained in the original materials, (ii) you must provide attribution to Blueprint, and if possible provide a reference to the Site, (iii) the material must be printed in its entirety without modification, reformatting or adaptation of any kind, (iv) any such copies are subject to the terms and conditions of this Agreement and remain the property of Blueprint, and (v) you agree to advise any person to whom you share the materials as to this Agreement and they must agree to abide by this Agreement. You may not sell or modify the material or reproduce, republish, display, publicly perform, distribute, or otherwise use the material in any way for any public or commercial purpose other than through the Services or the Site. Unauthorized use of the Services for any other purpose is prohibited. You may not decompile, reverse engineer, disassemble, rent, lease, loan, sell, sublicense, or create derivative works from the Site or the Services. Nor may you use any network monitoring or discovery software to determine the site architecture, or extract information about usage or users. You may not use any robot, spider, other automatic device, or manual process to monitor or copy our Site or its content without our prior written permission. Failure to abide by these conditions will immediately terminate this permission and may result in the infringement of the copyrights and/or trademarks and other proprietary rights of Blueprint or others.
          <br><br>
          4. User Content.
          <br><br>
          Except as provided in the BSLA or in this Agreement, Blueprint does not claim any ownership rights in the text, files, images, photos, video, sounds, musical works, works of authorship, or any other materials (collectively, “Content”) that you post or upload to the Site and/or through the Services (“User Generated Content”). After posting or uploading your User Generated Content to the Site and/or through the Services, you continue to retain all your ownership rights in such User Generated Content except as set forth in the EULA or in this Agreement.
          <br><br>
          You are solely responsible for any third party Content in your User Generated Content and for the use of the User Generated Content, including on our Site and through the Services. We reserve the right to remove such Content, your User Generated Content and/or any other content in our sole and absolute discretion.
          <br><br>
          In addition, the Site and/or Services may contain Content, under license to Blueprint Athletes from one or more third parties, in which you are featured and/or visible or which includes your name, sobriquet, professional name, image, likenesses, other identifications, and biographical material (“User Featured Content” and together, with User Generated Content, “User Content”).
          <br><br>
          You hereby grant to us and our licensees, distributors, agents, independent contractors, representatives and other authorized users (collectively, the “Blueprint Entities”), a perpetual, non-exclusive, irrevocable, royalty-free, sub-licensable and transferable (in whole or part) worldwide license to all copyrights, trademarks, patents, trade secrets, privacy and publicity rights and other intellectual property rights you own or control to use, reproduce, transmit, display, exhibit, distribute, index, comment on, modify, create derivative works based upon, perform and otherwise exploit such User Content, in whole or in part, in all media formats and channels now known or hereafter devised (including, without limitation, on the Site or any related or affiliated sites, on third party web sites, cable networks and stations, broadband and wireless platforms, and or on any other products and services) for any and all purposes including entertainment, news, advertising, promotional, marketing, publicity, trade or commercial purposes, all without further notice to you, with or without attribution, and without the requirement of any permission from or payment to you or to any other person or entity (the “Content License”). You appoint us as your agent with full power to enter into and execute any document and/or do any act we may consider appropriate to confirm, fully utilize, or enforce the grant of rights, consents, agreements, assignments and waivers set forth in this Agreement.
          <br><br>
          You represent and warrant to us that:
          <br><br>
          (i) you own the User Generated Content or otherwise have the legal right to post or transmit the User Generated Content in accordance with the terms of this Agreement,
          (ii) the posting or other transmission of the User Generated Content on or through the Services or Site or otherwise by Blueprint or the Blueprint Entities does not violate the privacy rights, publicity rights, intellectual property rights (copyrights, patents, trademarks), contract rights or any other rights of any person or entity,
          (iii) without limiting this Agreement, you have provided your consent to the use of User Featured Content to your school and/or applicable athletic organization from which Blueprint has obtained, directly or indirectly, such User Feature Content. To the extent that any of the User Content on the Site and/or Services features a child of User under the age of 18, User is deemed to have consented to the Content License on such child’s behalf and the foregoing representations with regard to such Content, and such child shall also be deemed to be a User for purposes of this Agreement.;
          <br><br>
          (iv) you have no agreement with or obligations to any third party with respect to the rights herein granted which conflict or interfere with or adversely affect any of the provisions of this Agreement or the use or enjoyment by us of any of the rights herein granted You have secured and will maintain all rights necessary for us to use and enjoy the rights herein granted. You have not sold, assigned, transferred or conveyed, and will not sell, assign, transfer, or convey, to any party any right, title, or interest in and to the rights herein granted or any part thereof, adverse to or in derogation of the rights herein granted to us; and
          (v) If you are under eighteen (18) years of age, you further warrant and represent that you either: (I) are an emancipated minor, or (II) possess legal parental or guardian consent to enter into this agreement and use the site and services.
          (vi) To the extent any “moral rights,” “ancillary rights,” or similar rights in or to the User Content exist and are not exclusively owned by us, you agree not to enforce any such rights as to us or the Blueprint Athletes Entities, and you shall procure the same agreement not to enforce from any others who may possess such rights. You agree to pay for all royalties, fees, and any other monies owing to any person or entity by reason of any User Content posted by you to or through the Services.
          5. Infringing Content; Digital Millennium Copyright Act
          <br><br>
          We reserve the right to remove any User Content that is alleged to infringe the copyright of a third party or otherwise violates any third party rights and/or to suspend or terminate a User’s access privileges in the event of repeat infringement by a User.
          <br><br>
          If you are a copyright owner or authorized agent and believe that your work has been copied and posted on the Services in a way that constitutes copyright infringement, or if you believe that your rights have otherwise been violated by the Services, you may submit a notification pursuant to the Digital Millennium Copyright Act (“DMCA”) by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
          <br><br>
          (i) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyright interest or the person whose rights have been violated;
          (ii) a description of the copyrighted work that you claim has been infringed or the particular rights violated;
          (iii) if applicable, a description of where the material that you claim is infringing is located on the Site;
          (iv) your address, telephone number, and email address;
          (v) a written statement by you that you have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law or with regard to the rights violated; and
          (vi) a statement by you, made under penalty of perjury, that the above information in your notice is accurate.
          <br><br>
          Please submit notifications of claimed infringement to our e-mail account below. If you believe that your User Content that was removed (or to which access was disabled) is not infringing, or that you have the authorization from the copyright owner, the copyright owner’s agent, or pursuant to the law, to post and use the material in your User Content, you may send a counter-notice pursuant to the DMCA containing the following information to the Copyright Agent:
          <br><br>
          (i) your physical or electronic signature;
          (ii) identification of the User Content that has been removed or to which access has been disabled and the location at which the User Content appeared before it was removed or disabled;
          (iii) a statement that you have a good faith belief that the User Content was removed or disabled as a result of mistake or a misidentification of the User Content; and
          (iv) your name, address, telephone number, and e-mail address, a statement that you consent to the jurisdiction of federal courts in California, and a statement that you will accept service of process from the person who provided notification of the alleged infringement.
          If a counter-notice is received by the Copyright Agent, Blueprint may, in its sole discretion, send a copy of the counter-notice to the original complaining party informing that person that it may replace the removed User Content or cease disabling it in 10 business days. Unless the copyright owner files an action seeking a court order against the User Content provider, member or user, the removed User Content may be replaced, or access to it restored.
          <br><br>
          6. Blueprint Athletes Intellectual Property.
          <br><br>
          The Site and the Services, as well as certain Content available therein (together, “Blueprint Intellectual Property”), are protected by copyright, trademark, patent, trade secret and other intellectual property laws in the United States and other countries, and Blueprint owns and retains all such rights in the Blueprint Intellectual Property.
          <br><br>
          Without limiting the foregoing, Blueprint, protected under international law, the laws of the United States and other countries. Other parties’ trademarks used, depicted or identified on this Site are the property of their respective owners, used here by permission, and may be registered in one or more countries. Use on this Site of the trademark(s) (including, but not limited to, names and logos) of any other party is not intended to imply Blueprint's affiliation with or endorsement of that party, or that party’s sponsorship or endorsement of Blueprint and their products or services.
          <br><br>
          7. User Conduct.
          <br><br>
          You agree not to use the Services or the Site to take any action(s) that, (and your continued use of the Site and Services are conditioned on not taking any action(s) that):
          <br><br>
          patently offensive and promotes racism, bigotry, hatred or physical harm of any kind against any group or individual; harasses or advocates harassment of another person or group; exploits people in a sexual or violent manner; or contains nudity, violence, or offensive subject matter or contains a link to an adult website;
          solicits personal information; provides any User telephone numbers, street addresses, last names, URLs or email addresses; involves the transmission of “junk mail,” “chain letters,” or “unsolicited mass mailing”, “instant messaging”, “phishing”, “spimming” or “spamming”; contains restricted or password only access pages or hidden pages or images (those not linked to or from another accessible page);
          promotes information that you know is false or misleading or promotes illegal activities or conduct that is abusive, threatening, obscene, defamatory or libelous; promotes an illegal or unauthorized copy of another person’s copyrighted work, such as providing pirated computer programs or links to them, providing information to circumvent manufacture-installed copy-protect devices, or providing pirated music or video or links to pirated files;
          furthers or promotes any criminal activity or enterprise or provides instructional information about illegal activities including, but not limited to making or buying illegal weapons, violating someone’s privacy, or providing or creating computer viruses;
          involves commercial activities and/or sales without our prior written consent such as contests, sweepstakes, barter, advertising, or pyramid schemes;
          are contrary to Blueprint's public image, goodwill, or reputation;
          infringe on our or any third party’s copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;
          express or imply that any of your statements, activities or causes are endorsed by us, without our prior written consent in each instance;
          transmit any trade secret or other material, non-public information about any person, company or entity without the authorization to do so;
          “frame” or “mirror” any part of the Site without our prior written authorization;
          distribute any virus, worm or other similar or deleterious files, scripts or programming routines;
          interfere with or disrupt any services or equipment with the intent of causing an excessive or disproportionate load on the infrastructure of Blueprint or its licensors or suppliers;
          forge headers or otherwise manipulate identifiers in order to disguise the origin of any submission; and/or
          execute any form of network monitoring or run a network analyzer or packet sniffer or other technology to intercept, decode, mine or display any packets used to communicate between the Website’s servers or any data not intended for you.
          Further, you agree not to use the Services or the Site to participate in:
          <br><br>
          criminal or tortious activity, including child pornography, fraud, trafficking in obscene material, drug dealing, gambling, harassment, stalking, spamming, spimming, sending of viruses or other harmful files, copyright infringement, patent infringement, or theft of trade secrets or violation of the privacy or publicity rights of third parties;
          advertising to, or solicitation of, any User to buy or sell any products or services through the Services. You may not transmit any chain letters or junk email to other Users. It is also a violation of these rules to use any information obtained from the Services in order to contact, advertise to, solicit, or sell to any User without their prior explicit consent. In order to protect our Users from such advertising or solicitation, Blueprint reserves the right to restrict the number of emails that a User may send to other Users;
          You agree not to use the Services or the Site in any manner that violates or is otherwise not in accordance with the terms of the subscription package in which you are currently enrolled (“Account”). Any violation of the following usage rules may result suspension of the Services and/or loss of Account access:  
          <br><br>
          You agree not to attempt to impersonate another User or other individual, and you acknowledge that the Services are for public and not private communications and that you have no expectation of privacy with regard to any User Content. We cannot guarantee the security of any information you disclose; you make such disclosures at your own risk. Also, you should be skeptical about information provided by others, and you acknowledge that the use of any User Content on this Site is at your own risk.
          <br><br>
          If you become aware of misuse of the Services by any person, please contact Blueprint. Blueprint reserves the right, in its sole discretion, to reject, refuse to post or remove any posting (including private messages) by you, or to restrict, suspend, or terminate your access to all or any part of the Site or Services at any time, for any or no reason, with or without prior notice, and without liability.
          <br><br>
          You agree to indemnify and hold Blueprint Athletes, the Blueprint Entities, and their subsidiaries, and affiliates, and their respective officers, agents, partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys’ fees, made by any third party due to or arising out of your use of the Site and Services in violation of this Agreement and/or arising from a breach of this Agreement and/or any breach of your representations and warranties set forth above and/or if any User Content posted on the Site or through the Services causes Blueprint to be liable to another third party or User.
          <br><br>
          8. Privacy.
          <br><br>
          You agree to the terms of the Blueprint Privacy Policy, as it may be updated from time to time, a copy of which is posted on the Site.
          <br><br>
          9. Links to Other Sites.
          <br><br>
          The Site contains links to web sites not operated or maintained by Blueprint. These links are provided solely as a convenience to you and not as an endorsement by Blueprint of the contents of such third party web sites. Blueprint is not responsible for the content of the sites of others and makes no representation regarding the content or accuracy of materials on such sites. If you decide to access linked third-party web sites, you do so at your own risk, and waive any and all claims against Blueprint regarding the inclusion of links to outside web sites or your use of those web sites.
          <br><br>
          10. Disclaimers.
          <br><br>
          DISCLAIMERS OF WARRANTIES: BLUEPRINT DOES NOT WARRANT THAT THE SITE OR SERVICES WILL OPERATE ERROR-FREE OR THAT THIS SITE AND ITS SERVER ARE FREE OF COMPUTER VIRUSES AND/OR OTHER HARMFUL MATERIALS. IF YOUR USE OF THE SITE OR THE SERVICES RESULTS IN THE NEED FOR SERVICING OR REPLACING EQUIPMENT OR DATA, BLUEPRINT IS NOT RESPONSIBLE FOR THOSE COSTS. THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” BASIS WITHOUT ANY WARRANTIES OF ANY KIND. Blueprint, TO THE FULLEST EXTENT PERMITTED BY LAW, DISCLAIMS ALL WARRANTIES, INCLUDING THE WARRANTY OF MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS, AND THE WARRANTY OF FITNESS FOR PARTICULAR PURPOSE. Blueprint MAKES NO WARRANTIES ABOUT THE ACCURACY, RELIABILITY, COMPLETENESS, OR TIMELINESS OF THE SERVICES.
          <br><br>
          DISCLAIMER OF CONSEQUENTIAL DAMAGES: EXCEPT AS OTHERWISE REQUIRED BY APPLICABLE LAW, NEITHER BLUEPRINT NOR THE BLUEPRINT ENTITIES OR THEIR DIRECTORS, EMPLOYEES, LICENSORS, CONTENT PROVIDERS, AFFILIATES OR OTHER REPRESENTATIVES WILL BE LIABLE FOR ANY DAMAGES WHATSOEVER, INCLUDING WITHOUT LIMITATION ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL AND EXEMPLARY DAMAGES, ARISING FROM THE USE OF THIS SITE AND SERVICES, EVEN IF THE PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
          <br><br>
          REMEDY: IF YOU ARE DISSATISFIED WITH THE SERVICES, YOUR EXCLUSIVE REMEDY SHALL BE TO CEASE USING THE SERVICES.
          <br><br>
          Some U.S. states and foreign countries do not permit the exclusion or limitation of implied warranties or liability for certain categories of damages. Therefore, some or all of the limitations above may not apply to you to the extent they are prohibited or superseded by state or national provisions. Blueprint makes no representation of any kind with respect to the applicability and enforceability of laws or policies of countries other than the United States over the content of this site and the provisions of this Agreement.
          <br><br>
          11. Dispute Resolution.
          <br><br>
          This Agreement shall be interpreted, construed and governed by the laws of the State of California, USA, however, Blueprint shall have the right to commence and prosecute any legal or equitable action or proceeding before any non-U.S. court of competent jurisdiction to obtain injunctive or other relief.
          <br><br>
          12. Other Miscellaneous Terms.
          <br><br>
          Should any clause of this Agreement be found unenforceable, wherever possible this will not affect any other clause and each will remain in full force and effect. Blueprint's failure to expressly enforce any provision of this Agreement does not waive its rights to enforce that or any other provision. Blueprint may assign, transfer, or delegate this Agreement or any right or obligation or remedy hereunder (in whole or in part) in its sole discretion.
          <br><br>
          13. NCAA Regulations / Other Regulations.
          <br><br>
          Blueprint Athletes is in no way affiliated with or sponsored by the NCAA. You are responsible for your own activities in connection with the Site, including your use of the Services. Accordingly, you are responsible for knowing and complying with the NCAA’s rules, regulations, and laws or other similar rules, regulations, and laws (collectively, “b”). Blueprint is not responsible if you do not abide by NCAA Regulations (or any other similar rules or regulations) in connection with your use of the Site, and/or Services. If you act in violation of the NCAA Regulations, Blueprint may take reasonable steps in response, including, but not limited to, termination of your access to and use of the Site and/or reporting of such conduct to the NCAA, the authorities, or other appropriate entity. Blueprint does not knowingly promote any violations of NCAA Regulations (or any other similar rules or regulations).
          <br><br>
          14. Notice for California Users
          <br><br>
          Under California Civil Code Section 1789.3, California Website users are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 N. Market Blvd., Suite S-202, Sacramento, California 95834, or by telephone at (800) 952-5210.
          <br><br>
          Updated October 10, 2017
        </p>
      </div>
    </div>
  </div>
</div>
