<div class="jumbotron-white">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h1>Privacy Policy</h1>
        <hr>
        <p>
            Privacy is paramount to Blueprint Athletes ("Blueprint"). This Privacy Policy details how we may collect, use, disclose, transfer, and store your information.
            <br><br>
            1. Personal Information Collection & Usage
            <br><br>
            Personal information is data that can be used to uniquely identify or contact a single person. We may use personal information to provide our products and services, as well as for purposes such as auditing, data analysis, and research to improve Blueprint's products, services, and customer communications. You may be asked to provide personal information when in contact with Blueprint. Blueprint and its partners may share personal information and use it as described in this Privacy Policy. Such information may be combined with other information to provide and improve products, services, content, and advertising. By providing us with personal information about yourself through any means, you consent to the collection, use, disclosure and transfer of that information, and to us sending you marketing communications related to our products and services. If you do not provide us with requested personal information we may not be able to provide you with some or all of Blueprint's products and services.
            <br><br>
            2. Personal Information Collection
            <br><br>
            When you visit our websites, download or install a software application, create a registered user account, purchase a product, register a product, complete a survey or download a software update, we may collect information, including: name, mailing address, phone number, email address, contact preferences, credit card information and payment history.
            <br><br>
            We may also collect such information when you contact us in person (for example, at clinics, conferences, workshops, seminars and other events), via telephone, facsimile or email. When you send us an email, that address will be recorded automatically for purposes of replying to your email. Otherwise, we will use the email address you provide on request for regular communications with you. By providing your email address, you are subscribing to our newsletter and product updates, which may include information about new features and new services. You may unsubscribe from this content by following the steps detailed in the email footer.
            <br><br>
            We may receive and store certain types of website usage information when you visit our websites. Website usage information helps us determine how visitors arrive, what type of content is most popular and which visitors are interested in particular content and advertising. We may collect the page visited, the time, the source of the request, the type of browser making the request, the preceding page view and other information about your engagement. We may also collect your IP address and your Device Identifier. A Device Identifier is a number automatically assigned to the computer, cell phone or tablet used to access the Internet. We may associate your Device Identifier with the personal information you provide.
            <br><br>
            Certain of our websites can be used anonymously. We do not collect personally identifiable information about you if you only browse such sites. However, if you choose to access our websites anonymously, we may not be able to provide you with particular products or services. We do collect personal information if you provide any such personal information, register as a user, provide details at an event, complete a survey or supply personal details through any other form of correspondence. Blueprint uses this information to provide products and services, as well as for billing, identification, authentication, products and services improvement, contact and research purposes.
            <br><br>
            Analytics of Collected Information
            <br><br>
            In order to improve our software and user experience, we may collect and store certain information about you and your use of our software. This information may include: date; time; computer identity; operating system; language; region; log preferences; registration key; license level; license code; license code status; code registration date; last validation date; ethernet address; IP address; mac address; licensed software name and version; OS version; and any other information about user actions required to improve, support and maintain our software.
            <br><br>
            3. Cookies
            <br><br>
            A cookie is a small amount of data which often includes an anonymous unique identifier that is sent to your browser from a website and stored on your computer’s hard drive. We use cookies to record current session information.
            <br><br>
            4. Web Beacons
            <br><br>
            Our website may contain electronic images (generally, single-pixel .gif images) called “web beacons.” Web beacons allow Blueprint and third parties to monitor and collect information about the viewer of the web page, web-based document, e-mail message or other communication, such as the type of browser requesting the web beacon, the Device Identifier of the device that the web beacon is sent to and the time the web beacon was viewed. Blueprint uses web beacons to count web browsers, visits and page views. It also uses beacons to monitor traffic and conversion patterns, to personalize your experience, including advertising and content, and to determine whether or not messages were opened, links were clicked or notifications/offers were acted upon.
            <br><br>
            5. User Profile Pages
            <br><br>
            These pages may contain: name, home and email addresses, phone number, school (name, city and state), graduation year, GPA, ACT/SAT scores, transcript parent/guardian information (name, relation, e-mail and phone), age, birth date, photograph, height, weight, jersey number, twitter handle, sports position, performance scores (40-yd-dash, agility shuffle, powerball, vertical jump), speed and strength information, sports awards and sports videos featuring the athlete. This information can be accessed by the athlete, coaches, team administrators and verified recruiters. The public will have limited access, excluding: phone number, age, birth date, graduation year, home and email addresses, GPA, ACT/SAT scores and transcript parent/guardian information (name, relation, e-mail and phone) and sports awards.
            <br><br>
            6. Data Storage
            <br><br>
            Blueprint uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage and related technology required to run the Blueprint website, and such third party vendors may collect and store your data on behalf of Blueprint. Blueprint owns the code, databases and all rights to therein. Blueprint incorporates reasonable safeguards to help protect and secure your personal information. However, no data transmission over the Internet, or wireless transmission, or electronic storage of information can be guaranteed to be 100% secure. Please note that Blueprint cannot ensure or warrant the security of any information you transmit to Blueprint via its websites and or products, and you do so at your own risk. The websites are general audience websites.
            <br><br>
            7. Disclosure to Third Parties
            <br><br>
            Personal information is not shared with or sold to other organizations for commercial purposes, except to provide products or services you’ve requested, when we have your permission, or under the following circumstances:
            <br><br>
            It is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person, violations of Terms of Service, or as otherwise required by law.
            <br><br>
            If Blueprint Athletes is acquired by or merged with another company. If this happens we will notify you before information is transferred.
            <br><br>
            We share information with our partners, licensees, agents, representatives, distributors, independent contractors and/or authorized third party.
            <br><br>
            8. Hosted Service Partners and Their Privacy Options
            <br><br>
            Our network advertising partners may include Facebook, Google, Twitter, and their advertising networks. Facebook and Google use cookies, web beacons, and similar technologies to collect information and use that information to provide measurement services and target ads. To opt-out at any time please contact us.
            <br><br>
            9. Children
            <br><br>
            In accordance with the Children’s Online Privacy Protection Act (“COPPA”), If you are under thirteen (13) years of age, you further warrant and represent that you possess legal parental or guardian consent to enter into this agreement and use the site and services. we may seek to obtain verifiable parental consent in order to proceed with creating accounts.
            <br><br>
            If a student’s school or teacher elects to utilize student accounts within the Blueprint Athletes App on a shared device (or any other devices) in school, the school will be responsible for obtaining any necessary parental consent under COPPA to create the student accounts.
            <br><br>
            If you have reason to believe that a child under the age of 13 has, without parental consent, provided Personal Data to Blueprint Athletes through the Services, please contact us, and we will endeavor to remove that information from our databases.
            <br><br>
            10. Our Company-Wide Commitment to Your Privacy
            <br><br>
            Blueprint is committed to the protection of your personal information. To make sure your personal information is secure, we communicate our privacy and security guidelines to Blueprint employees and strictly enforce privacy safeguards within the company. Please read this policy before visiting our websites, accessing our services, or providing any personal information to Blueprint Athletes. If you wish not to have your information used and disclosed as provided in this policy please do not provide such information, or contact us for further assistance. In doing so, please understand that your access to information, services and other benefits may be substantially limited.
            <br><br>
            13. Changes
            <br><br>
            Blueprint Athletes may periodically update this policy. By using the websites and products, you acknowledge your agreement to the terms of this Privacy Policy. We will notify you about significant changes in the way we treat personal information by sending a notice to the email address specified in your membership account or by placing a prominent notice on our website.
            <br><br>
            14. Privacy Questions
            <br><br>
            If you have any questions or concerns about Blueprint Privacy Policy or data processing, please contact Blueprint at: blueprint.athletes@gmail.com
            <br><br>
            Updated October 10, 2017
        </p>
      </div>
    </div>
  </div>
</div>
