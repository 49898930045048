<div class="jumbotron-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a routerLink="/">Home</a>
                <p style="color: #FFFFFF"> | </p>
                <p>Contact</p>
            </div>
        </div>
    </div>
</div>
<div class="jumbotron-contact">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <img src="../assets/img/photos/blueprint_basketball.jpg">
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <h1>Contact Us</h1>
                <p>Leave us a message ...</p>

                <form [formGroup]="contactForm" [hidden]="success" (ngSubmit)="onSubmit()">
                    <!-- NAME -->
                    <mat-form-field>
                        <input matInput type="text" placeholder="Your Name" formControlName="name" required>
                        <mat-error *ngIf="name.invalid && name.touched">
                            Please provide your name.
                        </mat-error>
                    </mat-form-field>

                    <!-- EMAIL -->
                    <mat-form-field>
                        <input matInput type="email" placeholder="Email" formControlName="email" required>
                        <mat-error *ngIf="email.invalid && email.touched">
                            Please provide a valid email.
                        </mat-error>
                    </mat-form-field>

                    <!-- PHONE -->
                    <mat-form-field>
                        <input matInput type="number" placeholder="Your Phone Number" formControlName="phone" required>
                        <mat-error *ngIf="phone.invalid && phone.touched">
                            Please provide your phone number.
                        </mat-error>
                    </mat-form-field>

                    <!-- MESSAGE -->
                    <mat-form-field>
                        <textarea matInput placeholder="Please provide a message." formControlName="message" required></textarea>
                        <mat-error *ngIf="message.invalid && message.touched">
                            Please provide us with more information here.
                        </mat-error>
                    </mat-form-field>

                    <!-- SUBMIT BUTTON -->
                    <button mat-button [disabled]="contactForm.invalid" color="primary">SEND FORM</button>
                </form>

                <div *ngIf="success">
                    <div class="card">
                        <h1><i class="far fa-paper-plane"></i> Message Sent!</h1>
                        <p>Thank you for your message,<br>We be in contact with you as soon as possible.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
