<div class="jumbotron-home">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <!-- <h1 class="rellax" data-rellax-speed="2">Blueprint</h1>
                <h2 class="rellax" data-rellax-speed="5">Athletes</h2>
                <h3 class="rellax" data-rellax-speed="7">Basketball Training</h3> -->
                <h1>Blueprint</h1>
                <h2>Athletes</h2>
                <h3>Basketball Training</h3>
                <a target="_blank" href="https://apps.apple.com/app/apple-store/id1352866958#?platform=iphone">
                    <img src="/assets/img/logos/app_store_logo.png">
                </a>
                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.blueprintathletes.android">
                    <img src="/assets/img/logos/google_play_store.png">
                </a>
             </div>
        </div>
    </div>
</div>

<div class="jumbotron-app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/app/app.png">
                <h1>New App <b style="color: #49ABF0; font-weight: normal;">Redesign</b> & <b style="color: #49ABF0; font-weight: normal;">Additional Features</b> !</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>Structured workouts and drills developed by NBA and D1 players and coaches to help you master basketball fundamentals, improve strength and conditioning, and develop key habits that lead to progress and success.</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-blueprint">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/blueprint_athlete.jpg">
                <h1>Are <b style="color: #49ABF0">you</b> a <br> Blueprint <br> Athlete?</h1>
                <p>A Blueprint Athlete is a player that is motivated from within. They are humble enough to know that they have to continually work on improving themselves. They are smart enough to understand that helping other people grow won’t take away from their growth. And they are tough enough to stay the course regardless of what life throws at them.</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-coaches">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>​<b style="color: #00AAFF;">ACCESS</b> drills from <b style="color: #00AAFF;">HALL OF FAME</b> coaches!</h1>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/jon-horford-beilein.jpg">
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/jon-horford-florida.jpg">
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-about">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-basketball-ball"></i>
                <h1>Basketball Training</h1>
                <p>Improve fundamental basketball training skills like coordination, shooting, dribbling, passing and footwork.</p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-dumbbell"></i>
                <h1>Strength & Conditioning</h1>
                <p>Increase core strength and endurance with our in-gym and at-home strength and conditioning workouts designed by the University of Michigan's men's basketball head strength and conditioning coach.</p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-calendar-check"></i>
                <h1>Mental Focus</h1>
                <p>From motivating daily mantras to practicing habit development, we help you implement key strategies to increase mental strength and focus.</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-mission">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/horford-bucks.jpg">
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <p>As a former D1 and professional basketball player, I understand what it means to put in the blood, sweat, and tears as an athlete. Working to earn a full scholarship to play college basketball at the University of Michigan provided me with the skills, structure, and training required for ultimate success.</p>
                <p>I created Blueprint Athletes based on this deep-rooted knowledge and experience as both a player and later as a coach. While each player’s journey may look different, there are essential fundamentals at the core of every successful athlete. My goal is to offer a one-stop, affordable solution for student-athletes of all ages and all skill levels who are looking to improve their game.</p>
                <p><b>Are you a Blueprint Athlete?</b> <br><br> Be humble. <br> Help others. <br>Stay the course.</p>
                <!-- <h1>"THE RIGHT WAY!"</h1>
                <p>The positive habits and values I've developed through basketball drills and training have shaped who I am today, on and off the court.​</p>
                <p>I want to use my lessons learned from playing under hall of fame coaches to help youth student athletes of all age groups to improve basketball fundamentals, teamwork abilities, and leadership skills.</p>
                <p>The game is more than just dribbling and shooting a basketball. That is the why I created Blueprint Athletes."</p> -->
                <h1>- Jon Horford</h1>
                <h6>Founder | Blueprint Athletes</h6>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <img class="img_large" src="/assets/img/photos/blueprint_court.jpg">
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <p>BE HUMBLE. <br> HELP OTHERS. <br> ​STAY THE COURSE.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img class="img_small" src="/assets/img/photos/jon-al-horford-camp-kids-1.jpg">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img class="img_small" src="/assets/img/photos/jon-al-horford-camp-kids-2.jpg">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img class="img_small" src="/assets/img/photos/jon-al-horford-camp-kids-3.jpg">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img class="img_small" src="/assets/img/photos/jon-al-horford-camp-kids-4.jpg">
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-partners">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p>​Excited to be working with:</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img src="/assets/img/logos/camp_sanderson_logo.png">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img src="/assets/img/logos/boys_and_girls_club_grand_rapids.png">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img src="/assets/img/logos/boys_and_girls_club_lansing.png">
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 col-6">
                <img src="/assets/img/logos/gus_macker_logo.png">
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-app-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Download our free app for Android and iPhone!</h1>
                <img src="/assets/img/app/app.png">
                <div class="store-buttons">
                    <a target="_blank" href="https://apps.apple.com/app/apple-store/id1352866958#?platform=iphone">
                        <img src="/assets/img/logos/app_store_logo.png">
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.blueprintathletes.android">
                        <img src="/assets/img/logos/google_play_store.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>