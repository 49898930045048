<div class="jumbotron-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a routerLink="/">Home</a>
                <p style="color: #FFFFFF"> | </p>
                <p>App Features</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-intro">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <img src="/assets/img/logos/logo.png">
                <h1>A new era of Basketball</h1>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-app">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <hr>
                <h1><b style="color: var(--blue)">Blueprint</b> Athletes</h1>
                <p>If you’re looking to improve your game for any reason, whether it’s for your team performance, for college-level play or as a recreational player, the Blueprint Athletes Basketball Training App will help you become a better, more well-rounded athlete.</p>
                <img src="/assets/img/app/app_courses.png">
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-guides">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>App Guides</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="banner-blueprint-bball">
                    <div class="content">
                        <i class="fas fa-basketball-ball"></i>
                        <h1>Blueprint Basketball</h1>
                        <p>Daily video workouts structured from beginner to advanced. Each lesson builds on itself for increased endurance, muscle memory, and skill mastery.</p>
                        <p>Focus on specific skills like shooting off the dribble, single ball stationary dribbling, and more.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="banner-blueprint-junior">
                    <div class="content">
                        <i class="fas fa-basketball-ball"></i>
                        <h1>Blueprint Junior</h1>
                        <p>Specifically designed for student-athletes with no strength training experience.</p>
                        <p>Focuses on getting athletes comfortable with basic strength training movements.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="banner-blueprint-weights">
                    <div class="content">
                        <i class="fas fa-dumbbell"></i>
                        <h1>Weight Room Strength & Conditioning</h1>
                        <p>Never be intimidated by the weight room machines and equipment again! We will walk you through key exercises for you to use in the gym to build tone, strength and stamina. Our easy-to-navigate tutorials provide a structured set of guidelines that will get you in shape and provide valuable conditioning.</p>
                        <p>In-the-gym strength and weight training videos designed by the University of Michigan’s men’s basketball head strength and conditioning coach.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="banner-blueprint-home">
                    <div class="content">
                        <i class="fas fa-running"></i>
                        <h1>At Home Strength & Conditioning</h1>
                        <p>Stay in shape and on track when you're not at the gym. Each of our workouts was specifically designed for you to do at home. Staying conditioned off the court is essential in order to keep your stamina up and skills sharp.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row card-left">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/blueprint_bg_one.png">
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <i class="fas fa-basketball-ball"></i>
                <h2>Blueprint Basketball</h2>
                <p>Daily video workouts structured from beginner to advanced. Each lesson builds on itself for increased endurance, muscle memory, and skill mastery.</p>
                <p>Focus on specific skills like shooting off the dribble, single ball stationary dribbling, and more.</p>
            </div>
        </div>
        <div class="row card-right">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <i class="fas fa-basketball-ball"></i>
                <h2>Blueprint Junior</h2>
                <p>Specifically designed for student-athletes with no strength training experience.</p>
                <p>Focuses on getting athletes comfortable with basic strength training movements.</p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/blueprint_bg_two.png">
            </div>
        </div>
        <div class="row card-left">
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/blueprint_bg_three.png">
            </div>
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <i class="fas fa-dumbbell"></i>
                <h2>Weight Room Strength & Conditioning</h2>
                <p>Never be intimidated by the weight room machines and equipment again! We will walk you through key exercises for you to use in the gym to build tone, strength and stamina. Our easy-to-navigate tutorials provide a structured set of guidelines that will get you in shape and provide valuable conditioning.</p>
                <p>In-the-gym strength and weight training videos designed by the University of Michigan’s men’s basketball head strength and conditioning coach.</p>
            </div>
        </div>
        <div class="row card-right">
            <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                <i class="fas fa-running"></i>
                <h2>At Home Strength & Conditioning</h2>
                <p>Stay in shape and on track when you're not at the gym. Each of our workouts was specifically designed for you to do at home. Staying conditioned off the court is essential in order to keep your stamina up and skills sharp.</p>
            </div>
            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                <img src="/assets/img/photos/blueprint_bg_four.png">
            </div>
        </div> -->
    </div>
</div>

<div class="jumbotron-app-features">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>App Features</h1>
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                <i class="far fa-check-circle"></i>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                <p><b>Guides:</b> Daily video workouts structured from beginning to advanced. Each lesson builds on itself for increased endurance, muscle memory, and skill mastery.</p>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                <i class="far fa-check-circle"></i>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                <p><b>Focused Skill Sets:</b> Focus on specific skills like shooting off the dribble, single ball stationary dribbling, and more.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                <i class="far fa-check-circle"></i>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                <p><b>Strength and weight training:</b> Videos designed by the University of Michigan’s men’s basketball head strength and conditioning coach.</p>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
                <i class="far fa-check-circle"></i>
            </div>
            <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10 col-10">
                <p><b>Tracking:</b> Track your improvement directly inside the app.</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <p>BE HUMBLE. <br> HELP OTHERS. <br> ​STAY THE COURSE.</p>
            </div>
        </div>
    </div>
</div>

<!-- <div class="jumbotron-features">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Blueprint Basketball Guides</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-award"></i>
                <h2><b style="color: #2ECC71">A1:</b> Beginner Guide</h2>
                <p>For players looking to master the basics</p>
                <h2><b style="color: #2ECC71">A2:</b> Beginner Shooting Guide</h2>
                <p>Shooting focused drills for beginners</p>
                <h2><b style="color: #2ECC71">A3:</b> Beginner Dribbling Guide</h2>
                <p>Dribbling focused drills for beginners</p>
                <h2><b style="color: #2ECC71">A4:</b> Beginner Post Moves Guide</h2>
                <p>Post moves focused drills for beginners</p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-medal"></i>
                <h2><b style="color: #F39C12">B1:</b> Intermediate Guide</h2>
                <p>For players looking to develop new skills</p>
                <h2><b style="color: #F39C12">B2:</b> Intermediate Shooting Guide</h2>
                <p>Shooting focused drills for intermediates</p>
                <h2><b style="color: #F39C12">B3:</b> Intermediate Dribbling Guide</h2>
                <p>Dribbling focused drills for intermediates</p>
                <h2><b style="color: #F39C12">B4:</b> Intermediate Post Moves Guide</h2>
                <p>Post moves focused drills for intermediates</p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <i class="fas fa-trophy"></i>
                <h2><b style="color: #E74C3C">C1:</b> Advanced Guide</h2>
                <p>For players looking to become an elite basketball player</p>
                <h2><b style="color: #E74C3C">C2:</b> Advanced Shooting Guide</h2>
                <p>Shooting focused drills for advanced players</p>
                <h2><b style="color: #E74C3C">C3:</b> Advanced Dribbling Guide</h2>
                <p>Dribbling focused drills for advanced players</p>
                <h2><b style="color: #E74C3C">C4:</b> Advanced Post Moves Guide</h2>
                <p>Post moves focused drills for advanced players</p>
            </div>
        </div>
        <div class="row justify-content-center" style="background: #F8F8F8;">
            <div class="col-12">
                <h1>Blueprint Junior Guides</h1>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <h2>A1: Blueprint Junior Cycle 1</h2>
                <p>Off-Season Cycle 1</p>
            </div>
            <div class="col-lg-4 col-md-12 col-sm-12 col-12">
                <h2>A1: Blueprint Junior Cycle 2</h2>
                <p>Off-Season Cycle 2</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h1>In-Season Guides</h1>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <h2>01: Stationary Shooting</h2>
                <p>Shooting drills to improve form and shooting touch</p>
                <h2>02: Shooting on the Move</h2>
                <p>Drills to help you get more comfortable / accurate while on motion</p>
                <h2>03: Shooting off the Dribble</h2>
                <p>Shooting drills that help you transition from dribbling to shooting</p>
                <h2>04: Dribble Drive Finishing</h2>
                <p>Drills to help you get to the basketball easier</p>
                <h2>05: Finish Around The Basket</h2>
                <p>Drills that will help you improve your touch in the paint</p>
                <h2>06: Back to the Basket Post Moves</h2>
                <p>Drills that will help you bemore comfortable playing with your back to the basket</p>
                <h2>07: Face-Up Post Moves</h2>
                <p>To help you score in the post further away from the basket</p>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                <h2>08: Hand Speed and Coordination</h2>
                <p>Drills that will help better ball control</p>
                <h2>09: Single Ball Stationary Dribbling</h2>
                <p>To help improve your ball handling ability</p>
                <h2>10: Two Ball Stationary Dribbling</h2>
                <p>Drills that will help you improve your two ball stationary dribbling</p>
                <h2>11: Single Ball Dribbling on the Move</h2>
                <p>Drills that will improve your ability to move with the ball</p>
                <h2>12: Two Ball Dribbling on the Move</h2>
                <p>Drills that will improve your ability to use both hands while dribbling on the move</p>
                <h2>13: Series</h2>
                <p>Drills that will allow you to work on and combimbine a variety of skills</p>
            </div>
        </div>
    </div>
</div> -->

<div class="jumbotron-app-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <h1>Download our free app for Android and iPhone!</h1>
                <img src="/assets/img/app/app.png">
                <div class="store-buttons">
                    <a target="_blank" href="https://apps.apple.com/app/apple-store/id1352866958#?platform=iphone">
                        <img src="/assets/img/logos/app_store_logo.png">
                    </a>
                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.blueprintathletes.android">
                        <img src="/assets/img/logos/google_play_store.png">
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
