import { Component, OnInit } from '@angular/core';
// import Rellax from 'rellax/rellax.min';
// import ScrollOut from 'scroll-out/dist/scroll-out.min.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    // const rellax = new Rellax('.rellax');
    // ScrollOut({
    //   threshold: .6
    // });
  }
}
