<div class="jumbotron-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a routerLink="/">Home</a>
                <p style="color: #FFFFFF"> | </p>
                <p>Sign Up</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-signup">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                <h1>Player & Team Signup</h1>
                <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Odio qui libero adipisci quia a culpa obcaecati molestias deleniti doloribus accusamus, illo beatae atque excepturi eum. Aliquid atque laboriosam recusandae earum.</p>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <a (click)="signupPlayer();">
                    <img  src="/assets/img/photos/player-signup.jpg" alt="">
                    <h1>Player Signup</h1>
                    <h2>(Or Upgrade)</h2>
                </a>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                <a (click)="signupTeam();">
                    <img (click)="signupTeam();" src="/assets/img/photos/team-signup.jpg" alt="">
                    <h1>Team Signup</h1>
                    <h2>(Up to 12 Players)</h2>
                </a>
            </div>
        </div>
    </div>
</div>
