<div class="jumbotron-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12">
                <a routerLink="/">Home</a>
                <p style="color: #FFFFFF"> | </p>
                <p>Profile</p>
            </div>
        </div>
    </div>
</div>

<div class="jumbotron-profile">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                <mat-card>
                    <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout" [hidden]="subscriptionActive" >
                        <div class="form-row">
                            <label for="card-info"><b>Purchase a Subscription For Full Access</b></label>
                            <br><br>
                        </div>
                        <div class="form-row">
                            <mat-radio-group name="subscription" label="Purchase Subscription" [(ngModel)]="subscriptionSelection" (ngModelChange)="updatePaymentValue()">
                                <mat-radio-button value="yearly" color="primary">Yearly {{ yearlyPriceString }}</mat-radio-button>
                                </mat-radio-group>
                        </div>
                        <div class="form-row">
                            <div id="coupon-errors" role="alert" *ngIf="couponError">{{ couponError }}</div>
                            <div id="coupon-status" role="alert" *ngIf="couponStatus">{{ couponStatus }}</div>                            <mat-form-field class="full-width">
                                <input  matInput  placeholder="Enter Team Name" name="coupon" [(ngModel)]="couponEntered">
                            </mat-form-field>
                        </div>
                        <div class="form-row" class="full-width">
                            <div id="card-info" #cardInfo></div>

                            <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
                        </div>
                        <button class="button" type="submit" color="primary">{{ checkoutButtonString }}</button>
                        <button class="logout_button" (click)="logout()">Logout</button>
                    </form>
                    <mat-label [hidden]="!subscriptionActive">
                        Thank you for purchasing a team subscription this code is vaild for only 15 users.
                        <br><br>
                        Download the app from Android's Play Store or iPhone's App Store by selecting an image below and logging in with your following credentials:
                        <br><br>
                        <b>Team Name:</b> {{ couponEntered }} <br>
                        <b>Team Coupon Code:</b> "{{ createdCoupon }}"
                        <div class="store-buttons">
                            <a target="_blank" href="https://apps.apple.com/app/apple-store/id1352866958#?platform=iphone">
                                <img src="/assets/img/logos/app_store_logo.png">
                            </a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.blueprintathletes.android">
                                <img src="/assets/img/logos/google_play_store.png">
                            </a>
                            <button class="logout_button" (click)="logout()">Logout</button>
                        </div>
                    </mat-label>
                </mat-card>
            </div>
        </div>
    </div>
</div>