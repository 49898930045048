import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';

import * as firebase from 'firebase/app';
import { AngularFireFunctions } from '@angular/fire/functions';
@Component({
  selector: 'app-dialog-team',
  templateUrl: './dialog-team.component.html',
  styleUrls: ['./dialog-team.component.scss']
})
export class DialogTeamComponent implements OnInit {

  coachFormGroup: FormGroup;
  playerFormGroup: FormGroup;
  loading = false;
  success = false;


  // TeamId" : *Guid*,
  // TeamId: Guid;
  // "TeamName" : *String*,
  constructor (
    private router: Router,
    private afAuth: AngularFireAuth,
    private dialogRef: MatDialog
  ) { }

  passwordHidden = true;
  email: string;
  password: string;
  error = '';

  ngOnInit() {
    this.afAuth.auth.onAuthStateChanged(user => {
      if (user) {
        this.router.navigate(['/coach-profile']);
        this.dialogRef.closeAll();
      }
    });
  }

  login(): void {
    if (this.email && this.password) {
      this.afAuth.auth.signInWithEmailAndPassword(this.email, this.password)
        .then(value => console.log('Logged in ' + value.user.email)
        )
        .catch(reason => {
          if (reason.code === 'auth/user-not-found') {
            this.error = 'Did not find user, double check email or register instead.';
          } else {
            this.error = reason.message;
          }
          console.log(reason);
        })
        this.dialogRef.closeAll();
    } else {
      alert('Invalid credentials');
      this.error = 'Email & Password are required';
    }
  }

  register() {
    if (this.email && this.password) {
      this.afAuth.auth.createUserWithEmailAndPassword(this.email, this.password)
        .then(value => console.log(`Registered User${value.user.toJSON().toString()}`))
        .catch(reason => this.error = reason.message);
    }
  }

  close(){
    this.dialogRef.closeAll();
  }
}
